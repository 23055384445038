<template>
	<div class="flex flex-col w-full h-screen">
    <template v-if="!isLoading && (!items || items.length === 0)">
        <EmptyState  class="bg-white" isFull
        v-bind="emptyViewBinding" :action="() => toggleSlideover(true)" />
    </template>
    <template v-else>
      <div class="flex flex-col w-full mt-6 mb-2 sm:px-4">
        <slot name="header">
          <Header @onAdd="() => toggleSlideover(true)" 
            :title="listHeader.title" 
            :btnLabel="listHeader.btnText" 
            :count ="listHeader.count" 
            :searchbox="listHeader.searchbox" 
            :filter="listHeader.filter"  
            :sort="listHeader.sort" 
            :isShowSearchBox="listHeader.showSearchbox"/>
        </slot>
      </div>
      <div class="flex flex-col w-full h-screen px-4 sm:px-8 space-y-2">
        <div class="flex flex-col border border-gray-300" v-for="item in items" :key="item" >
          <Items v-bind="item" @onClick="onClickItem(item)"/>
        </div>
        <CardFooterWithPageButtons  class="px-0 bg-opacity-0"
          :onChangePerPage="(count) => perPageChanged(count)"
          :totalRecords="pageTotalRecords"
          :perPage="recordPerPage"
          :currentPage="recordActivePage"
          :onChangePage="(page) =>  pageChanged(page)"/>
      </div>
      <slot name="add">
         <div>fallback</div>
      </slot>
    </template>
	</div>
</template>

<script>
import EmptyState from '../EmptyState/Simple/Simple'
import Header from '../../molecules/ListViewHeader/WithFilter/ListViewHeader.vue'
import Items from '../../molecules/CardListItem/CardWithBadge/CardWithBadge.vue'
import Icon from '../../atoms/Icons/Icons.vue'
import Text from '../../atoms/Text/Text.vue'
import Simple from '../Tables/Simple/Simple.vue'
import CardFooterWithPageButtons from '../Paginations/CardFooterWithPageButtons/CardFooterWithPageButtons.vue'
import {c} from '../../constants.js'
/**
 * - Use it to show a LinkedRecordList
 */
export default {
	components: {
		Header, Items, Text, Simple, Icon, CardFooterWithPageButtons,EmptyState
	},
	props: {
		/**
		 * Use it for list view items
		 */
		items: {
			type: Array,
			default: () => []
		},
		listHeader: {
			type: Object,
			default: () => {}
		},
    recordPerPage: {
      type: Number,
      default: 10,
    },
    /**
     * total records in table
     */
    pageTotalRecords: {
      type: Number,
      default: 0,
    },
    /**
     * current page of record table
     */
    recordActivePage: {
      type: Number,
      default: 1,
    },
    /**
     * action to get page data
     */
    getPageData: {
      type: Function,
      defaullt: () => {},
    },
       /**
     * loading status
     */
    isLoading: {
      type: Boolean,
      default: false,
    },
    onClickItem: {
      type: Function,
      default: () => {}
    },
    toggleSlideover: {
      type: Function,
      default: () => {}
    },
    viewName: {
      type: String,
      default: ''
    },
		emptyViewBinding: {
			type: Object,
			default: () => {}
		}
	},
  data() {
	  return {
      c
    }
  },
  methods: {
    async perPageChanged(count) {
      debugger;
      await this.actions[this.viewName].onChangePerPage(parseInt(count));
      await this.actions[this.viewName].onChangePage(1);
      await this.getPageData();
    },
    async pageChanged(page) {
      await this.actions[this.viewName].onChangePage(page);
      await this.getPageData();
    },
  }
}
</script>
