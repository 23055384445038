<template>
  <div>
    <DetailViewHeader :title="detailViewHeader.title" :options="detailViewHeader.options"
                      :backUrl="detailViewHeader.backUrl" :backLabel="detailViewHeader.backLabel"
                      :actionUrl="detailViewHeader.actionUrl" :actionLabel="detailViewHeader.actionLabel"
                      :isActive="detailViewHeader.isActive" customClass="mx-2 sm:mx-6" class="mt-4" />
    <SubNavigationBar hide-icon :tabs="subNavigationBar" customClass="mt-4 sm:mt-0 mx-0 sm:mx-8"/>
    <FormFieldGroup :items="formFieldGroup" class="sm:px-8 lg:px-0"/>
  </div>
</template>

<script>
import ListViewItem from "@/components/organisms/ListViewItem/ListViewItem";
import DetailViewHeader from "@/components/molecules/DetailViewHeader/DetailViewHeader";
import SubNavigationBar from '@/components/molecules/SubnavigationBar/SubnavigationBar.vue'
import FormFieldGroup from "@/components/organisms/FormFieldGroup/FormFieldGroup";
import {c} from '@/components/constants'
export default {
  components: {
    ListViewItem,
    DetailViewHeader,
    SubNavigationBar,
    FormFieldGroup
  },
  data() {
    return {
      detailViewHeader: c.detailViewHeader,
      subNavigationBar: c.subNavigationBar,
      formFieldGroup: c.formFieldGroup
    }
  }
}
</script>

<style scoped>

</style>
