<template>
	<div class="flex flex-col pb-0 sm:pb-4 lg:divide-y divide-white lg:divide-gray-300">
		<div v-for="item in items" :key="item" class="h-full grid-cols-1 mt-0 sm:mt-8 lg:grid-cols-7 lg:px-8 gap-x-7" :class="item.isForm ? 'grid' : 'hidden sm:grid'">
			<Simple v-if="item.open"
				:title="item.header"
				:textHtml="item.textHtmlMobile"
				:onNoClick="() => {item.open = false; items = [...items];}"
				:open="item.open"/>
			<div class="h-full col-span-2 mt-0 sm:mt-4" :class="item.isForm && 'pt-7 sm:pt-0'">
				<div class="lg:flex-col">
					<div v-if="item.header" class="flex items-center lg:flex-row pl-4 lg:pl-0">
						<Text size="xl" weight="semibold" color="gray-900" :content="item.header" customClass="md:text-4xl md:font-bold"/>
						<Icon name="InformationCircleIcon" class="block mt-1 ml-2 lg:hidden" @click="()=> {item.open = true; items = [...items];}" color="gray-600" :isOutline="true" />
					</div>
					<Text v-if="item.header" size="sm" :content="item.description"  class="hidden lg:flex" />
					<Text size="sm" element="span" color="grayhelptext" :content="item.hideText" @click="()=> {item.detailHidden = !item.detailHidden; items = [...items]}"  class="hidden my-2  underline lg:flex cursor-pointer"/>

				</div>
				<div v-if="!item.detailHidden && item.textHtml" v-html="item.textHtml" class="flex-col hidden h-full mb-8 text-xs lg:flex">
				</div>
			</div>
			<hr class="block mt-2 sm:hidden"/>
			<div v-if="item.isForm" class="col-span-5 mx-0  mt-4">
				<div class="shadow-lg h-fit rounded-md">
					<div class="px-4 py-8 space-y-4 bg-white border-t border-l border-r border-gray-300 rounded-t-md">
						<div class="flex flex-col w-full gap-4 my-1 lg:flex-row">
							<div v-if="item.name" class="flex w-full mx-0 sm:mx-1 lg:w-1/2">
								<Input
									:value="item.name.value"
									name= 'inputEl'
									:label="item.name.label"
									type= 'text' />
							</div>
							<div v-if="item.types" class="flex w-full mx-0 sm:mx-1 lg:w-1/2">
								<Select
									class="w-full"
									:items="item.types.list"
									:selectedItem="item.types.selected"
									:label="item.types.label" />
							</div>
						</div>
						<div class="flex flex-col w-full gap-4 my-1 lg:flex-row">
							<div v-if="item.demographics" class="flex w-full mx-0 sm:mx-1 ">
								<Select
									class="w-full"
									:items="item.demographics.list"
									:selectedItem="item.demographics.selected"
									:label="item.demographics.label" />
							</div>
						</div>
						<div class="flex flex-col w-full gap-4 my-1 lg:flex-row">
							<div v-if="item.interestAndActivities" class="flex w-full mx-0 sm:mx-1  lg:w-1/2">
								<Select
									class="w-full"
									:items="item.interestAndActivities.list"
									:selectedItem="item.interestAndActivities.selected"
									:label="item.interestAndActivities.label" />
							</div>
							<div v-if="item.markets" class="flex w-full mx-0 sm:mx-1 lg:w-1/2">
								<Select
									class="w-full"
									:items="item.markets.list"
									:selectedItem="item.markets.selected"
									:label="item.markets.label" />
							</div>
						</div>
						<div class="flex flex-col w-full gap-4 my-1 lg:flex-row">
							<div v-if="item.estimatedMarketSize" class="flex w-full mx-0 sm:mx-1  lg:w-1/2">
								<Input
									:value="item.estimatedMarketSize.value"
									name= 'inputEl'
									:label="item.estimatedMarketSize.label"
									type= 'text' />
							</div>
							<div v-if="item.researchSource" class="flex w-full mx-0 sm:mx-1  lg:w-1/2">
								<Input
									:value="item.researchSource.value"
									name= 'inputEl'
									:label="item.researchSource.label"
									type= 'text' />
							</div>
						</div>
						<div class="flex flex-col w-full my-1 lg:flex-row">
							<div  v-if="item.notes" class="flex flex-col w-full mx-0 sm:mx-1 ">
								<label class="text-sm font-semibold text-gray-700 label-sm">{{item.notes.label}}</label>
								<textarea class="flex w-full h-16 p-2 border border-gray-300 rounded-md ring-1 ring-gray-300"
									v-model="item.notes.value"
									:label="item.notes.value"></textarea>
							</div>
						</div>
					</div>
					<div class="flex flex-row w-full bg-gray-100 border border-gray-300 rounded-b-none sm:rounded-b-md">
						<div  v-if="item.notes" class="flex w-3/4">
						</div>
						<div  v-if="item.notes" class="flex flex-row items-end justify-end w-1/4 my-4 mr-4 sm:mr-3 lg:mr-5">
							<Button
								content="Cancel"
								:variant="null"
								customClass="bg-white text-black underline px-2 bg-gray-100"
							/>
							<Button
								content="Save"
								variant="primary"
								customClass="ml-4 px-4"
								:isLoading="isLoading"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {ref} from 'vue'
import Button from '../../atoms/Button/Button.vue'
import Input from '../../molecules/Inputs/Component/Component.vue'
import Select from '../../molecules/Selects/Component/Component.vue'
import Icon from '../../atoms/Icons/Icons.vue'
import Text from '../../atoms/Text/Text.vue'
import Simple from '../Modals/Simple/Simple.vue'
/**
 * - Use it to show a FormFieldGroup
 */
export default {
	components: {
		Button,
		Select,
		Input,
		Text,
		Icon,
		Simple
	},
	props: {
		/**
		 * Use it for FormFieldGroup items
		 */
		items: {
			type: Array,
			default: () => []
		},
		isLoading: {
			type: Boolean,
			default: true
		}

	}
}
</script>
