<template>
    <div class="px-0 sm:px-8 py-0 sm:py-4">
        <div class="inline-flex flex-row justify-between w-full space-x-8">
            <div class="flex flex-col lg:flex-row">
                <h2 class="text-xl flex items-center font-medium tracking-tight text-gray-900 mr-2">
                    {{title}}
                </h2>
                <Badge v-if="isActive" :is-rounded="true" text-size="sm" px="3" py="2" bg-color="gray-400"
                    text-color="white" text-weight="medium" label="Activity" class="w-20 mt-2 lg:mt-1 self-start"/>
            </div>

            <div class="sm:block hidden">
                <Dropdown label="Options" :options="options"></Dropdown>
            </div>
            <div class="sm:hidden block">
                <Dropdown :options="options"></Dropdown>
            </div>
        </div>
        <div class="inline-flex flex-col sm:flex-row mt-6">
            <Link :url="backUrl" :leading="true" v-if="backLabel" custom-class="mr-4 mb-2">
            <span class="inline-flex items-center underline text-sm text-primary-900">
                <Icons name="ArrowLeftIcon" size="4" color="primary-900" customClass="mr-2" hover-text-color="primary-900" />
                {{backLabel}}
            </span>
            </Link>
            <Link :url="actionUrl" :leading="true" v-if="actionLabel">
            <span class="inline-flex items-center underline text-sm text-primary-900">
                <Icons name="MapIcon" size="4" color="primary-900" customClass="mr-2" hover-text-color="primary-900" />
                {{actionLabel}}
            </span>
            </Link>
        </div>
    </div>
</template>

<script>
import Badge from "../Badge/Basic/Basic.vue";
import Dropdown from "../Dropdown/Dropdown/Dropdown.vue";
import Link from "../../atoms/Link/Link.vue";
import Icons from "../../atoms/Icons/Icons.vue";

export default {
    name: "DetailViewHeader",
    components: {
        Icons,
        Link,
        Badge,
        Dropdown
    },
    props: {
        title: {
            type: String,
            default: "#13 Keep track of what exercises to do",
        },
        options: {
            type: Array,
            default: null,
        },
        backUrl: {
            type: String,
            default: "",
        },
        backLabel: {
            type: String,
            default: "",
        },
        actionUrl: {
            type: String,
            default: "",
        },
        actionLabel: {
            type: String,
            default: "",
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        customClass: {
            type: String,
            default: ''
        }
    },
};
</script>
